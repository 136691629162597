import React from 'react';
import { NextPage, GetStaticProps } from 'next';
import Link from 'next/link';
import Head from 'next/head';
import { css } from '@emotion/react';
import { Headline, Body, Button } from '@sumup/circuit-ui';
import { useTranslation } from 'next-i18next';
import styled from '@emotion/styled';
import { useRouter } from 'next/router';

import styles from '../styles/layout.module.css';

import { Logo } from 'components/Logo';
import Card from 'components/FixedCard';
import Error from 'components/revamp/Error';
import { getErrorStaticProps } from 'services/staticGeneration/error';
import { isNewSupportCenterEnabled } from 'src/utils/featureFlag';

const Container = styled('section')(
  () => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 450px;
    margin: 0 auto 12px;
  `,
);

const NotFound: NextPage = () => {
  const { t } = useTranslation();
  const router = useRouter();

  if (isNewSupportCenterEnabled(router.locale)) {
    return (
      <div className={styles.container}>
        <Error />
      </div>
    );
  }

  return (
    <>
      <Head>
        <title>{t('common.page_not_found')}</title>
      </Head>
      <Container>
        <a href="https://sumup.com" target="_blank" rel="noopener noreferrer">
          <Logo />
        </a>
        <Card>
          <Headline as="h2" size="one">
            {t('common.page_not_found')}
          </Headline>
          <Body>{t('common.error_page_message')}</Body>

          <Link href="/" passHref>
            <Button variant="primary">{t('common.go_to_homepage')}</Button>
          </Link>
        </Card>
      </Container>
    </>
  );
};

export const getStaticProps: GetStaticProps = getErrorStaticProps;

export default NotFound;

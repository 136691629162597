import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { SumUpLogo } from '@sumup-oss/icons';

export const Logo = styled(SumUpLogo)(
  () => css`
    display: block;
    max-width: 120px;
  `,
);

import { useRouter } from 'next/router';
import React, { FC, useContext } from 'react';
import { Headline, Body, Button, Image } from '@sumup/circuit-ui';

import useContentfulError from './hooks/useContentfulError';
import styles from './error.module.css';

import { AppContext } from 'src/context/AppContext';

const ErrorPage: FC = () => {
  const router = useRouter();
  const { state } = useContext(AppContext);

  useContentfulError(router);

  return (
    state.errorPage && (
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.iconWrapper}>
            <Image
              className={styles.icon}
              src={state.errorPage.fields.icon.fields.file.url}
              alt={state.errorPage.fields.icon.fields.title}
            />
          </div>
          <Headline size="one" as="h1">
            {state.errorPage.fields.title}
          </Headline>
          <Body as="h2" className={styles.subTitle}>
            {state.errorPage.fields.subtitle}
          </Body>
        </div>
        <Button onClick={() => router.push('/')} variant="primary">
          {state.errorPage.fields.cta}
        </Button>
      </div>
    )
  );
};

export default ErrorPage;
